import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/SquareSection.css';
import Paragraph from './Paragraph';

function PresentationSection(props) {
  return (
    <div className="Section fade-in">
            <h1 className="Section-title">{props.title}</h1>
            <div className="PresentationSection-content">
              <section className="PresentationSection-section">
                <img src={props.img} alt={props.alt} className="PresentationSection-img"/>
              </section>
              <section className="PresentationSection-section PresentationSection-section-p">
              <Paragraph text={props.content} />
              <a className="resumeLink" href={props.link}>Check my resume</a>
              </section>
          </div>
    </div>
  );
}

export default PresentationSection;
