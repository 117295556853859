import PicturesSection from '../paragraphs/PicturesSection';
import DescriptionSection from '../paragraphs/DescriptionSection';
import '../../styles/Projects.css';

const ProjectTile = (props) => {
    return (
        <div className="ProjectTile">
            <h2 className="ProjectTileTitle">{props.name}</h2>
            <div className="ProjectTileContent">
                <div>
                    <PicturesSection content={props.pictures}/></div>
                <div>
                    <DescriptionSection content={props.description} link={props.link}/>
                </div>
            </div>      
        </div>
    );
}

export default ProjectTile;