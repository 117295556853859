import '../../styles/DropdownTile.css';
import { useState } from 'react';


const DropdownTile = (props) => {
    
    // declare a new state variable flag and the update function setFlag
    const [droppedDown, setDroppedDown] = useState(false);

    const dropDown = () => {
        console.log(droppedDown);
        setDroppedDown(!droppedDown);
    }

    const rotate = {
        transform: droppedDown ? 'rotate(90deg)' : '', 
        transition: 'transform 150ms ease', // smooth transition
       }

    return (
        <div className="DropdownTile" >
            <div className="DropdownHeader" onClick={dropDown}>
                <h1 className="DropdownTitle">{props.title}</h1>
                <div className="DropdownArrow" style={rotate}></div>
            </div>
            {droppedDown ? props.content : <div/>
        }
        </div>
    );
}

export default DropdownTile;