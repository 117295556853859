import '../../styles/DescriptionSection.css';
import Paragraph from './Paragraph';


const DescriptionSection = (props) => {
    return (
        <div className="DescriptionSection">
            <Paragraph text={props.content}/>
            {props.link ? <p>See more on <a href={props.link.link}>{props.link.name}</a></p> : <></>}
        </div>
    );
}

export default DescriptionSection;