import ProjectTile from "./ProjectTile";

const ProjectsTile = (props) => {
    return (
        <div>
            {
                props.projects.map(project => 
                    <ProjectTile {...project}/>
                )
            } 
        </div>
    );
}

export default ProjectsTile;