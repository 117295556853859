import '../styles/LegalNotice.css';
import Layout from '../layouts/Layout.js'
import '../styles/SquareSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route } from 'react-router-dom';

const LegalNotice = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (  
        <Layout child={
          <>
          <LegalNoticeContent></LegalNoticeContent>
          </>
        }>
        </Layout>
    )} /> 
  );
}

const LegalNoticeContent = () => {
  return (
    <div className="LegalNotice">
      <h1>Legal Notices</h1>
      <h2>I - Identification</h2>
      <h3>Site Owner</h3>
      <ul className="list">
        <li>Name : ROUX Sterenn</li>
        <li>Address : 22300 Lannion, France</li>
        <li>E-mail : by this form or contact@sterennroux.fr</li>
        <li>Design : ROUX Sterenn</li>
      </ul>
      <h3>Hosting</h3>
      <ul className="list">
        <li>Name : OVH</li>
        <li>Head office : 2 rue Kellermann - 59100 Roubaix - France</li>
        <li>Web site : www.ovh.com</li>
      </ul>
      <h3>Activities</h3>
      <ul className="list">
        <li>Showcase site presenting my achievements and skills.</li>
      </ul>

      <h2>II - Notice on the use of cookies & personal data</h2>
      <h3>Definition</h3>
      <p>
        A cookie is a small computer file, a tracer. It is used to analyse the behaviour of users when they visit a website, read an e-mail, install or use a software or a mobile application.
        <br/>
        <br/>
        The period of validity of the consent is a maximum of 13 months.
      </p>
      <h3>Uses</h3>
      <p>This site does not use cookies.</p>

      <h3>Contact information (form)</h3>
      <p>By submitting the contact form, you agree that the information entered may be used to reply.
        <br/>
        For any complaint, opposition, or rectification of data, please contact me by this form or by email at contact@sterennroux.fr .
      </p>
    </div>
  );
} 

export default LegalNotice;
