import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "../../styles/Header.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <div className="Navigation">
      <Navbar variant="dark" expand="lg">
      <img
                 alt="Sterenn Roux"
                 src={process.env.PUBLIC_URL + "/profileLogo.png"}
                 className="d-inline-block align-center"
               />
        <Link className="text-decoration-none" to={"/"}>
          <Navbar.Brand>{" Sterenn Roux"}</Navbar.Brand>
        </Link>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="justify-content-end"
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="me-auto">
            <Link className="text-decoration-none" to={"/about-me"}>
              <Nav.Link href="#about-me">About me</Nav.Link>
            </Link>

            <Link className="text-decoration-none" to={"/career"}>
              <Nav.Link href="#career">Career</Nav.Link>
            </Link>

            <Link className="text-decoration-none" to={"/projects"}>
              <Nav.Link href="#projects">Projects</Nav.Link>
            </Link>

            <Link className="text-decoration-none" to={"/contact"}>
              {" "}
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Navigation;
