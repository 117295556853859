import IconSection from './IconSection';
import '../../styles/Skills.css';
const Skills = () => {
    return (
      <div className="Skills">
        <IconSection iconUp={true} title="Development" icon={process.env.PUBLIC_URL + '/development.png'}></IconSection>
        <IconSection iconUp={false} title="English" icon={process.env.PUBLIC_URL + '/english.png'}></IconSection>
        <IconSection iconUp={true} title="Management" icon={process.env.PUBLIC_URL + '/managment.png'}></IconSection>
        <IconSection iconUp={false} title="Driving Licence" icon={process.env.PUBLIC_URL + '/icons/car.png'}></IconSection>
      </div>
    );
  }
  
  export default Skills;
