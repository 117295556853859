import '../styles/404.css';
import Layout from '../layouts/Layout.js'
import { Route } from 'react-router-dom';
import Paragraph from '../components/paragraphs/Paragraph';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome} from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap';
import {useWindowDimensions} from '../utils/useWindowDimentions.js';


const NotFound = ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={props => (  
          <Layout child={
            <>
            <NotFoundContent></NotFoundContent>
            </>
          }>
          </Layout>
      )} /> 
    );
  }

  

  const NotFoundContent = () => {
    const { width } = useWindowDimensions();
    const style = {
        backgroundImage: "url('" +process.env.PUBLIC_URL + "404-" + (width > 500 ? "" : "phone-" ) + "bg.png')",
    };
      return (
        <div className="notFound" style={style}>
        <p className="hudge">404</p>
        <Paragraph
            text ="Page not found"
            />
        <div className="notFoundContent">
        <div className="half">
            <Paragraph
            text ="You seem lost in the metaverse"
            />
            <Link to={'/'} ><Button className="backButton"><FontAwesomeIcon size="lg" icon={faHome} />   Go to the home page</Button></Link>
        </div>
        </div>
        </div> 
      );
  }

  export default NotFound;