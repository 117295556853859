import "../../styles/Timeline.css";
import TimelineElement from "./TimelineElement";

const Timeline = ({...props}) => {
    return (
      <>
      <div className="limit"></div>
      <div className="timeline">
      {
            props.content.map( (element, index) => 
              <TimelineElement side={index%2 !==0 ? "right" : "left"} content={element} icons={element.icons}/>,
           )
          } 
      </div>
      </>
    );
  }
  
export default Timeline;