import '../styles/Career.css';
import Layout from '../layouts/Layout.js'
import '../styles/SquareSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route } from 'react-router-dom';
import Timeline from '../components/timeline/Timeline';
import DropdownTile from '../components/tiles/DropdownTile';
import { Container } from 'react-bootstrap';

const Carrier = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (  
        <Layout child={
          <>
          <CarrierContent></CarrierContent>
          </>
        }>
        </Layout>
    )} /> 
  );
}

const education = [
  {
    name: "Engineering diploma in computer sciences and information technology",
    date: "Since 2020 September",
    description: "Engineering 3-years diploma in apprenticeship. Learning computer sciences and management and half-time developer in Orange.",
    location: {
      name: "ENSSAT - Lannion(22)",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2631.7338771433465!2d-3.464614884091369!3d48.72967651771457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48122bebcc946cff%3A0x7dde59569aaf04a2!2sEnssat%20-%20%C3%89cole%20Nationale%20Sup%C3%A9rieure%20des%20Sciences%20Appliqu%C3%A9es%20et%20de%20Technologie!5e0!3m2!1sfr!2sfr!4v1642006302364!5m2!1sfr!2sfr"
    },
    icons: [
        {
          title: "Development",
          src: '/development.png',
        },
        {
          title: "Database",
          src: '/database.png',
        },
        {
          title: "Networks",
          src: '/network.png',
        },
        {
          title: "English B2",
          src: '/english.png',
        },
        {
          title: "Management",
          src: '/managment.png',
        },
        {
          title: "App Conception",
          src: '/conception.png',
        },
      ]
  },
  {
    name: "Technological University Degree in computer sciences",
    date: "From 2018 September To 2020 August",
    description: "2-years diploma with second year in apprenticeship. Learning basics of computer sciences, and half-time developer in Orange during apprenticeship.",
    location: {
      name: "IUT - Lannion(22)",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.234576003116!2d-3.45400068486519!3d48.75831651570267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48122c034b5275af%3A0x355e62516b08c22f!2sIUT+Lannion!5e0!3m2!1sfr!2sfr!4v1554742315389!5m2!1sfr!2sfr"
    },
    icons: [
        {
          title: "Development",
          src: '/development.png',
        },
        {
          title: "Android Development",
          src: '/android.png',
        },
        {
          title: "Database",
          src: '/database.png',
        },
        {
          title: "Networks",
          src: '/network.png',
        },
        {
          title: "App Conception",
          src: '/conception.png',
        },
        {
          title: "Linux Administration",
          src: '/linux-penguin.png',
        },
      ]
  },
  {
    name: "Scientific Baccalaureate with Engineering Sciences option",
    date: "From 2015 September To 2015 July",
    description: "French baccalaureate of computer and engineering sciences.",
    location: {
      name: "Félix Le Dantec high school - Lannion(22)",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2631.6671083655906!2d-3.45032878409132!3d48.730952217624925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48122be106392311%3A0xbcec13bfa1d2e495!2sLyc%C3%A9e%20F%C3%A9lix%20Le%20Dantec!5e0!3m2!1sfr!2sfr!4v1642006403539!5m2!1sfr!2sfr"
    }
  },
];

const educationTimeline= <Timeline content={education}/>;

const experiences = [
  {
    name: "Aprenticeship in software development",
    date: "Since 2019 September",
    // eslint-disable-next-line no-multi-str
    description: "Half time software developer during my studies in apprenticeship.\
    \n(*)2nd year of bachelor : development of Android applications.\
    (*)1st year of engineering diploma : participation in the development of a progressive web app.\
    (*)2nd year: development of a multi-platform application in flutter.\
    (*)3rd year: antispoofing solution for dialler.",
    location: {
      name: "Orange - Lannion(22)",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.45160702253!2d-3.460647284090752!3d48.754171515992795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48122c0f43c9d087%3A0xa8599d4403837cb8!2sOrange%20Labs!5e0!3m2!1sfr!2sfr!4v1642006607700!5m2!1sfr!2sfr",
    },
    icons: [
      {
        title: "Management",
        src: '/managment.png',
      },
      {
        title: "App Conception",
        src: '/conception.png',
      },
      {
        title: "Android Development",
        src: '/android.png',
      },
      {
        title: "Web and Software Development",
        src: '/development.png',
      },
    ]
  },
  {
    name: "NoSQL Internship",
    date: "Summer 2022",
    description: "Foreign mobility period.\n(*)Big data research internship.(*)Cost model for MongoDB.",
    location: {
      name: "University of Bologna - Cesena (Italy)",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.45160702253!2d-3.460647284090752!3d48.754171515992795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48122c0f43c9d087%3A0xa8599d4403837cb8!2sOrange%20Labs!5e0!3m2!1sfr!2sfr!4v1642006607700!5m2!1sfr!2sfr",
    },
    icons: [
      {
        title: "Data",
        src: '/database.png',
      },
      {
        title: "English communication",
        src: '/english.png',
      },
    ]
  },
  {
    name: "McDonald's employee",
    date: "2018 August",
    description: "Many roles:\n (*)Takings orders at drive or inside the restrant. (*)Cleaning places. (*)Order preparation.",
    location: {
      name: "McDonald's - Lannion(22)",
      map: null,//"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.873639265317!2d-3.4631086848656976!3d48.74611051656071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48122bf647d760f1%3A0xe47ae83a7a11c749!2sMcDonald&#39;s!5e0!3m2!1sfr!2sfr!4v1554742868277!5m2!1sfr!2sfr"
    },
  },
];

const experienceTimeline= <Timeline content={experiences}/>;

const CarrierContent = () => {
  return (
    <div className="Career">
      <h1>My Career</h1>
      <Container>
      <DropdownTile content={educationTimeline} title="Education" /> 
      <DropdownTile content={experienceTimeline} title="Professionnal experience" />
      </Container>
        </div>
  );
} 

export default Carrier;
