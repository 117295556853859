import "../../styles/IconsList.css";
import IconSection from "./IconSection";

const IconsList = ({...props}) => {
    return (
      <div className="IconsList">
          {props.icons.map(icon => <IconSection iconUp={true} title={icon.title} icon={process.env.PUBLIC_URL + icon.src}/>)}
      </div>
    );
  }
  
export default IconsList;
