import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import LegalNotice from "./pages/LegalNotice";
import NotFound from "./pages/404";
import { Route, Redirect, Switch } from "react-router";
const routes = [
    {
        'name': 'about-me',
        'path': '/about-me',
        'component': <Home exact path="/about-me" />,
        'key': 'about-me',
    },
    {
        'name': 'career',
        'path': '/career',
        'component': <Career exact path="/career" />,
        'key': 'career'
    },
    {
        'name': 'projects',
        'path': '/projects',
        'component': <Projects exact path="/projects" />,
        'key': 'projects'
    },
    {
        'name': 'contact',
        'path': '/contact',
        'component': <Contact exact path="/contact" />,
        'key': 'contact'
    },
    {
        'name': 'legalNotices',
        'path': '/legal-notices',
        'component': <LegalNotice exact path="/legal-notices" />,
        'key': 'legalNotices'
    },
];

const Routes = () => {
  return (
        <Switch>
            <Route exact path="/"><Redirect to="/about-me" /></Route>
            {
            routes.map(route => 
                route.component
           )
          } 
         <Route component={NotFound} />
        </Switch>
  );
}
export default Routes;

