import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/SquareSection.css';
import '../../styles/Anim.css';

function ThirdSection(props) {
  return (
    <div className="ThirdSection fade-in">
            <h2 className="Section-title">{props.title}</h2>
            <div className="Section-content">
              {props.content}
            </div>
    </div>
  );
}

export default ThirdSection;
