import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'


function Footer() {
  return (
    <Card className="text-white Footer">
        <Card.Body className="FooterMain">
        <Link className="text-white" to={'/contact'}>Contact me!</Link>
        <br/>
        © 2021 Copyright: 
        <a className="text-white noDecoration" href="https://sterennroux.fr/"> Sterenn Roux </a>

        <Link className="text-white" to={'/legal-notices'}>Legal notices</Link>
    </Card.Body>
    <Card.Body className="FooterBtn" onClick={() => window.scrollTo(0, 0)}>
    <FontAwesomeIcon icon={faChevronUp} />  Top of page
    </Card.Body>
    </Card>
  );
}

export default Footer;

