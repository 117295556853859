import React, {useState, useRef} from 'react';
import {Form, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import emailjs from 'emailjs-com';
import '../styles/Contact.css';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const service = process.env.REACT_APP_EMAILJS_SERVICE;
const templateAuto = process.env.REACT_APP_EMAILJS_AUTO_REPLY;
const templateContact = process.env.REACT_APP_EMAILJS_CONTACT_FORM;
const user = process.env.REACT_APP_EMAILJS_USER;

const ContactForm = () => {
	emailjs.init(user);
  // declare states
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const contactForm = useRef();

  const refresh = () => {
    setEmail("");
    setSubject("");
    setMessage("");
    window.location.reload(false);
  }

  // when user click on submit
  const handleSubmit = async (event) => {
    setDisabled(true);
    event.preventDefault();
    let success = null;
    document.body.style.cursor = 'wait';
    // check if no empty
    if(email && subject && message){
     // await init(REACT_APP_EMAILJS_USER);
      await emailjs.sendForm(service, templateContact, contactForm.current, user)
      .then((result) => {
        success = true;
          // alert("Your message has been successfully sent.");
      }, (error) => {
        //console.log(error.text);
        // alert("Your message has not been sent, please try again.");
      });
      emailjs.sendForm(service, templateAuto, contactForm.current, user)
      .then((result) => {
        refresh();
          //console.log(result.text);
          //success = true;
      }, (error) => {
        refresh();
          //console.log(error.text);
      });
    }
    if(success){
      alert("Your message has been successfully sent.");
      refresh();
    } else {
      alert("Your message has not been sent, please try again.");
      setDisabled(false);
      document.body.style.cursor = 'default';
    }
    
  }
    return (
      <>
        <Form className="ContactForm" ref={contactForm} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <Form.Text className="text-muted">
            I'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
      
        <Form.Group className="mb-3" controlId="formBasicSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control type="text" placeholder="Subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)}/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control type="text" placeholder="Message" name="message" value={message} onChange={(e) => setMessage(e.target.value)}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check 
            className="ContactCheckbox" 
            type="checkbox"
            value={checked}
            onChange={(e) => checked ? setChecked(false) : setChecked(true)} 
            label="By submitting to this form, I agree that my information will be used in order to be contacted." 
          />
        </Form.Group>
          <Button type="submit" variant="primary" className="ContactButton" disabled={!(checked && email!=="" && subject!=="" && message!=="") || disabled}>
          Submit
          </Button>
      </Form>
      <div>
        <a href="https://www.linkedin.com/in/sterenn-roux/" className="linkedIn"><FontAwesomeIcon icon={faLinkedin} />  Contact me on LinkedIn</a>
      </div>
      </>


    );
}

export default ContactForm;

// En soumettant à ce formulaire, j'accepte que mes informations soient utilisées dans le but d'être recontacté.
