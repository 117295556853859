import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './Navigation';
import '../../styles/Header.css';

function Header() {
  return (
    <div className="Header">
      <Navigation />
    </div>
  );
}

export default Header;