import "../../styles/Timeline.css";
import IconsList from "../paragraphs/IconsList";
import Paragraph from "../paragraphs/Paragraph";

const TimelineElement = ({...props}) => {
    return (
      <div className={props.side+" timelineContainer"}>
          <div className="timelineContent">
				<time datetime="2018-09-04" className="timeline-item-details-date">{props.content.date}</time>
				<h3>{props.content.name}</h3>
        <h5>{props.content.location.name}</h5>
				<Paragraph text={props.content.description} />
				{props.content.location.map != null ? <iframe title="title" src={props.content.location.map} loading="lazy"></iframe> : <></>}
        {
          props.icons !== undefined ? 
          <>
          <h4 className="spaced">Skills improved</h4>
          <IconsList icons={props.icons}/>
          </>
          :
          <></>
        }
			</div>
      </div>
    );
  }
  
export default TimelineElement;