import '../styles/Projects.css';
import Layout from '../layouts/Layout.js'
import { Route } from 'react-router-dom';
import DropdownTile from '../components/tiles/DropdownTile';
import ProjectsTile from '../components/tiles/ProjectsTile';
import { Container } from 'react-bootstrap';

const Projects = ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={props => (  
          <Layout child={
            <>
              <ProjectsContent/>
            </>
          }>
          </Layout>
      )} /> 
    );
  }

  const ProjectsContent = () => {
    const rdt = {
      name: "Remote Device Tool",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/stf1.jpg",
            alt: "Stf",
          },
          {
            src: process.env.PUBLIC_URL + "/stf2.gif",
            alt: "Use of stf",
          },
        ]
      },
      description: "Since the last periods in Orange, I have been working on a tool called RDT (Remote Device Tool) which is based on the DeviceFarmer STF (Smartphone Test Farm) open-source project. STF is a service which lets user control and manage Android devices through a web browser. With a team member, we are in a process of redeveloping the web app using Flutter framework. Currently we are learning Flutter while implementing some features. The main purpose of the overhaul is to provide a desktop version of the application.\nDuring this period, I am also working as scrum master on the project.",
      link: {
        link : "https://github.com/DeviceFarmer/stf",
        name: "https://github.com/ DeviceFarmer/stf",
      }
    }

    const myorange = {
      name: "MyOrange Progressive Web App",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/myo1.png",
            alt: "my orange home french",
          },
          {
            src: process.env.PUBLIC_URL + "/myo2.png",
            alt: "my orange services",
          },
          {
            src: process.env.PUBLIC_URL + "/myo3.png",
            alt: "my orange home arabic",
          },
        ],
      },
      description: "For two periods of two months in the company, I participated in the development of the MyOrange Progressive Web App's overhaul. MyOrange is a customer area application for Orange customers. On this project I have learnt React.js which is a library used to develop Web Apps and Progressive Web Apps.\nMyOrange Progressive Web App is used in Middle-East and Africa where some countries have Arabic language as mother tongue. One of my missions on the application was to improve its Arabic language implementation. Indeed, as Arabic reading and writing goes from right to left, developers have to manage the design consequently. I therefore worked on the automatization of the inversion of the whole user interface. The goal being to only let the exceptions to be manually managed by the developers.",
    }

    const dialler = {
      name: "Orange Phone",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/dialler.png",
            alt: "Spam call",
          },
        ],
      },
      description: "During the summer period of 2020, I participated in the development of the Orange Phone Android application. Orange Phone is an application used by more than 1 million of users. It replaces the native dialer app in Android devices, and includes an anti-spam system (the user knows if the incoming call is malicious or telemarketing). In this project, I practiced Android development in an international team, based on Scrum agile method.",
      link: {
        link : "https://orange-telephone.com/?platform=android",
        name: "https://orange-telephone.com/?platform=android",
      }
    }
    const professionnalProjects = [rdt, myorange, dialler];

    const oaklabs = {
      name: "OakLabs",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/oaklabs/1.png",
            alt: "Camera",
          },
          {
            src: process.env.PUBLIC_URL + "/oaklabs/2.png",
            alt: "Detection",
          },
          {
            src: process.env.PUBLIC_URL + "/oaklabs/3.png",
            alt: "Pokemon",
          },
          {
            src: process.env.PUBLIC_URL + "/oaklabs/4.png",
            alt: "Pokedex",
          },
          {
            src: process.env.PUBLIC_URL + "/oaklabs/5.png",
            alt: "Catched Pokemon",
          },
          {
            src: process.env.PUBLIC_URL + "/oaklabs/6.png",
            alt: "Badges",
          },
          {
            src: process.env.PUBLIC_URL + "/oaklabs/7.png",
            alt: "Settings",
          },
          {
            src: process.env.PUBLIC_URL + "/oaklabs/8.png",
            alt: "Pokedex light",
          },
          {
            src: process.env.PUBLIC_URL + "/oaklabs/9.png",
            alt: "Search",
          },
        ],
      },
      description: "In a team of 5 we developed a Pokemon recognition mobile app. During 6 months we were working on conception, backend and frontend development in agile scrum method with a customer. To develop this app, we used React Native (frontend), FastAPI (backend) and PyTorch (AI recognition model). I personnaly worked in the project management and frontend development parts.",
    }

    const jee = {
      name: "Java Entreprise Edition",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/kiloupresquetout1.png",
            alt: "JEE first screenshot",
          },
          {
            src: process.env.PUBLIC_URL + "/kiloupresquetout2.png",
            alt: "JEE second screenshot",
          },
          {
            src: process.env.PUBLIC_URL + "/kiloupresquetout3.png",
            alt: "JEE third screenshot",
          },
        ],
      },
      description: "In a team of 4, we have designed a website of products reservation for a fake company. From the conception of the project using UML charts to the development in Java, we have worked during two months on this project. It enables me to use Java Entreprise Edition that I had never used before.",
    }

    const pbn = {
      name: "The luggage carrier",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/PBN.JPG",
            alt: "realisation",
          },
          {
            src: process.env.PUBLIC_URL + "/PBN.PNG",
            alt: "model designed",
          },
          {
            src: process.env.PUBLIC_URL + "/piecePBN.PNG",
            alt: "prodution to link the model and the mokup",
          },
        ],
      },
      description: "The luggage carrier was the subject of my second year's project in high school. We were a group of 4 students and we had only just one instruction: \"create a robot\". The goal of our luggage carrier was to carry heavy or light luggages and follow the user in order to help people with reduced mobility. The creation of the robot was divided into two parts:  the mechanical design (group of 2) and the electrical one (group of 2). I have worked on the second part by designing the model with SolidWorks and calculating the forces applied on our future prototype.",
    }

    const pokejuren = {
      name: "Pokejhuren",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/pokeJhuren.png",
            alt: "sprites",
          },
          {
            src: process.env.PUBLIC_URL + "/ligue.png",
            alt: "ligue Pokejhuren",
          },
          {
            src: process.env.PUBLIC_URL + "/pokeJhuren_moves.png",
            alt: "sprite moves",
          },
        ],
      },
      description: "PokeJhuren is a game inspired by the PokemonⒸ games. With two other students we have developed it for a baccalaureate project. To program it, we have used Processing IDE with a simplified Java language. In this project, my main part was to code the character's movements and its collisions with other objets. I have also implemented music Jhurens' (monsters as Pokemons) nursing.",
      
    }

    const locker = {
      name: "Bilock",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/bilock.jpg",
            alt: "model",
          },
        ],
      },
      description: "Bilock is an automated bike locker. It was a subject of my baccalaureate project. By groups of 4, we had to develop a robot linked with mathematics or physics. The idea came from our summer holiday where some ones of us had troubles with their bikes.\nThe creation of the robot was divided into two parts the mechanical design (group of 2) and the electrical one (group of 2). I was in the electronic team, and I developed the locker on an Arduino card with a RFID module, a hall effect sensor and a motor. My teammate developed a geolocation system in order to find the bike if stolen.",
    }

    const schoolProjects = [oaklabs, jee, locker, pokejuren, pbn];

    const ada = {
      name: "Ada Lovelace Challenge",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/ada_lovelace/lionsir1.png",
            alt: "home",
          },
          {
            src: process.env.PUBLIC_URL + "/ada_lovelace/lionsir2.png",
            alt: "register",
          },
          {
            src: process.env.PUBLIC_URL + "/ada_lovelace/crinihelp1.png",
            alt: "home",
          },
          {
            src: process.env.PUBLIC_URL + "/ada_lovelace/crinihelp2.png",
            alt: "add an announce",
          },
          {
            src: process.env.PUBLIC_URL + "/ada_lovelace/crinihelp3.png",
            alt: "connection",
          },
          {
            src: process.env.PUBLIC_URL + "/ada_lovelace/crinihelp4.png",
            alt: "dashboard",
          },
          {
            src: process.env.PUBLIC_URL + "/ada_lovelace/crinihelp5.png",
            alt: "register",
          },
        ],
      },
      description: "The Ada Lovelace project is a challenge for young British women in high school. Named as the first woman developer, the main purpose of the challenge is to initiate teens to computer science.\nNowadays in France, they are not well represented in this domain. During two days, the students are developing a website linked to a given theme. I have participated 7 times to the challenge.\n(*)From the first to the third edition as a teenager, I have discovered computer science thanks to the challenge and have learnt the basics of web development. (*)From the fourth to the sixth edition, I was a mentor. My role was to help a team to develop their ideas and bring support to the development. I also was a mentor for the 8th edition. (*)For the seventh edition, I am a part of the organization team in the communication pole.",
    }

    const personal_website = {
      name: "This website",
      description: "I developed this website during my free time to accompany my resume. From the conception to its publication, I developed it using React.js library only. I also setted up the production server by myself using nginx.",
      pictures: {
        type: "images",
        content : [
          {
            src: process.env.PUBLIC_URL + "/adobe-colors.png",
            alt: "colors",
          },
          {
            src: process.env.PUBLIC_URL + "/404-page.png",
            alt: "404 page not found",
          },
        ]
      }
    };

    const otherProjects = [ada, personal_website];


    const professionnal = <ProjectsTile projects={professionnalProjects}/>
    const school = <ProjectsTile projects={schoolProjects}/>
    const other = <ProjectsTile projects={otherProjects}/>
    return(
      <div className="Projects">
        <h1>My Projects</h1>
        <Container>
          <DropdownTile title="Professional projects" content={professionnal} />
          <DropdownTile title="School projects" content={school} />
          <DropdownTile title="Personal projects" content={other} />
        </Container>
      </div>
    );
  }

export default Projects;
