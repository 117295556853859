import '../styles/Home.css';
import Layout from '../layouts/Layout.js'
import '../styles/SquareSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route } from 'react-router-dom';
import ThirdSection from '../components/paragraphs/ThirdSection';
import PresentationSection from '../components/paragraphs/PresentationSection';
import Skills from '../components/paragraphs/Skills';
import Passions from '../components/paragraphs/Passions';
import { Link } from "react-router-dom";

const Home = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (  
        <Layout child={
          <>
          <HomeContent></HomeContent>
          </>
        }>
        </Layout>
    )} /> 
  );
}

const HomeContent = () => {
  return (
    <div className="Home">
      <h1>Who am I ?</h1>
          <div className="RowSquaresSection">
            <div className="Half-div">
            <PresentationSection
              title="About me"
              content={"I am an apprentice engineer in software development. It means that I am half-time software developer in Orange Lannion and half-time student at the engineering school of Lannion (ENSSAT). \n I am Looking for a cybersecutity PhD (subject and contract) about user data and privacy protection."}
              img={process.env.PUBLIC_URL + '/profilePicture.png'}
            />
            </div>
            <div className="Half-div">
              <ThirdSection
                title="Skills"
                content={<Skills/>}
              />
              <ThirdSection
                title="Passions"
                content={<Passions/>}
              />
            </div>
          </div>

          <div className="RowSquaresSection">
          <div className="Half-div">
          <h2  className="color-title">Current situation</h2>
              <ThirdSection
                title="Engineering diploma"
                content={
                  <div className='currentSituation'>
                    <p>ENSSAT - Lannion(22)</p>
                    <p>CLES 2 Certification. Equivalent to TOEIC B2</p>
                    <p>3 year diploma. Learning computer science and management.</p>
                    <div>
                      <Link className="link" to={"/career"}>Learn more</Link>
                    </div>
                    <div>
                      <Link className="link" to={"/projects"}>See projects</Link>
                    </div>
                  </div>
                }
              />
              <ThirdSection
                title="Aprenticeship in software development"
                content={
                <div className='currentSituation'>
                  <p>Orange - Lannion(22)</p>
                  <p>Since 2019. Software and web development.</p>
                  <div>
                    <Link className="link" to={"/career"}>Learn more</Link>
                    </div><div>
                  <Link className="link" to={"/projects"}>See projects</Link>
                  </div>
                </div>
              }
              />
            </div>
            <div className="Half-div">
            <h2 className="color-title">Latest projects</h2>
              <ThirdSection
                title="Remote Device Tool"
                content={<div className='currentSituation'>
                <p>Orange project</p>
                <p>Overhaul of the actual web app using Flutter. The main purpose is to learn Flutter which is a multi-platform framework.</p>
                <div>
                  <Link className="link" to={"/projects"}>Learn more</Link>
                </div>
              </div>
              }
              />
              <ThirdSection
               title="Java Enterprise Edition"
               content={<div className='currentSituation'>
               <p>ENSSAT project</p>
               <p>Deployed a web application to rent equipments using Java Enterprise Edition. </p>
               <div>
                 <Link className="link" to={"/projects"}>Learn more</Link>
               </div>
             </div>
             }
              />
            </div>
          </div>
        </div>
  );
} 

export default Home;
