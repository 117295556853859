import '../styles/Layout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Layout = ({child}) => {
  console.log(child);
  return (
      <div className="Layout">
        <Header/>
          <div className="content">
            {child}
          </div>
        <Footer/>
      </div>
  );
}


Layout.propTypes = {   
   
};

export default Layout;
