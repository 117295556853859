import '../styles/Home.css';
import Layout from '../layouts/Layout.js'
import '../styles/SquareSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route } from 'react-router-dom';
import ContactForm from '../components/ContactForm';

const Contact = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (  
        <Layout child={
          <>
          <ContactContent></ContactContent>
          </>
        }>
        </Layout>
    )} /> 
  );
}

const ContactContent = () => {
  return (
    <div className="Contact">
      <h1>Contact me</h1>
        <ContactForm />
    </div>
  );
} 

export default Contact;
