import '../../styles/Paragraph.css';
import PropTypes from 'prop-types';
import React from 'react';

class Paragraph extends React.Component {
render = () => {
  const tab = this.props.text.toString().split('\n');
  return (
  <div>
    {
    tab.map(str => {
      const list = str.split('(*)');
      return (list.length > 2) ?
        <ul>
          {list.shift()}
          {list.map(element => <li>{element}</li>)}
        </ul> : 
        <p>{str}</p>
      })
      }
  </div>
  );
}

}
Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
}
export default Paragraph;