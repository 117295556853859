import '../../styles/IconSection.css';
import {useMediaQuery} from 'react-responsive';


const IconSection = (props) => {

    const isSmallDevice = useMediaQuery({
        query: "(max-width: 990px)",
      });
    const isIconUp = props.iconUp || isSmallDevice;

    return (
        <div className="IconSection">
            <div className="IconSection-div">
            {isIconUp ? <div className="Icon"><img src={props.icon} alt={props.alt} /></div> : <p>{props.title}</p>}
            </div>
            <div className="IconSection-div">
            {isIconUp ? <p>{props.title}</p> : <div className="Icon"><img src={props.icon} alt={props.alt} /></div>}
            </div>

        </div>
    );
  }
  
  export default IconSection;
