import Carousel from 'react-bootstrap/Carousel';
import '../../styles/PicturesSection.css';

const PicturesSection = (props) => {
    return (
        <div className="PicturesSection">
            {props.content.type === "images" ?
                // carousel
                <>
                <Carousel className="Carousel">
                   {
                       props.content.content.map(picture => 
                        <Carousel.Item className="CarouselImg">
                        <img
                          className="CarouselImg"
                          src={picture.src}
                          alt={picture.alt}
                        />
                        <Carousel.Caption>
                          <h3>{picture.title}</h3>
                          <p>{picture.description}</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                       )
                   }
                </Carousel>
                </>
                : 
                //video
                <>
                  <video src={props.content.content[0].src}>

                  </video>
                </>
        }
     </div>
    );
}

export default PicturesSection;