import IconSection from './IconSection';
import '../../styles/Passions.css';
const Passions = () => {
    return (
      <div className="Passions">
            <IconSection iconUp={true} title="Dance" icon={process.env.PUBLIC_URL + '/dance.png'}></IconSection>
            <IconSection iconUp={false} title="Video Games" icon={process.env.PUBLIC_URL + '/video_games.png'}></IconSection>
            <IconSection iconUp={true} title="Sports" icon={process.env.PUBLIC_URL + '/icons/gym.png'}></IconSection>
      </div>
    );
  }
  
  export default Passions;
